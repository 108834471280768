/** @jsx jsx */
import { jsx, Styled } from "theme-ui"

import Layout from "components/layout"

const NotFoundPage = () => (
  <Layout>
    <Styled.h1>Not Found</Styled.h1>
    <Styled.p>
      You just hit a route that doesn&#39;t exist... the sadness.
    </Styled.p>
  </Layout>
)

export default NotFoundPage
